<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <!-- <el-form-item>
        <el-input v-model="dataForm.key" placeholder="参数名" clearable></el-input>
      </el-form-item> -->
      <el-form-item>
        <!-- <el-button @click="getDataList()">查询</el-button> -->
        <el-button v-if="isAuth('bus:busbuttons:save')" type="primary" @click="$router.go(-1)">返回</el-button>
        <label style="margin:0 20px">品牌：{{$route.query.brandname}}</label>
        <el-button v-if="isAuth('bus:busbuttons:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <!-- <el-button v-if="isAuth('bus:busbuttons:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <!-- <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column> -->
      <el-table-column
        type="index"
        width="50"
        header-align="center"
        align="center"
        label="序号">
      </el-table-column>
      <el-table-column v-if="$route.query.type == 1"
        prop="img"
        header-align="center"
        align="center"
        width="100"
        label="图片" :key="Math.random()">
        <template slot-scope="scope">
          <img style="height:50px" v-if="scope.row.img" :src="$imgPath+scope.row.img" />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="名称">
      </el-table-column>
      <el-table-column v-if="$route.query.type == 0"
        prop="commandbytes"
        header-align="center"
        align="center"
        label="默认值(关)" :formatter="formatCode">
      </el-table-column>
      <el-table-column v-if="$route.query.type == 0"
        prop="commandbytes1"
        header-align="center"
        align="center"
        label="默认值(开)" :formatter="formatCode">
      </el-table-column>
      <el-table-column v-if="$route.query.type == 1"
        prop="itemsNum"
        header-align="center"
        align="center"
        label="按钮数量">
      </el-table-column>
      <el-table-column
        prop="feedback0"
        header-align="center"
        align="center"
        label="反馈值关" :formatter="formatCode">
      </el-table-column>
      <el-table-column
        prop="feedback1"
        header-align="center"
        align="center"
        label="反馈值开" :formatter="formatCode">
      </el-table-column>
      <el-table-column
        prop="isAutoCrc"
        header-align="center"
        align="center"
        width="60"
        label="CRC">
        <template slot-scope="scope">
          <span v-if="scope.row.isAutoCrc == 1">是</span><span v-else>否</span>
        </template>
      </el-table-column>
      
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" @click="copyHandle(scope.row.id)">复制</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import Sortable from 'sortablejs'
  import AddOrUpdate from './busbuttons-add-or-update'
  export default {
    data () {
      return {
        dataForm: {
          key: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate
    },
    activated () {
      this.getDataList()
    },
    methods: {
      formatCode(row, column, cellValue, index) {
        try {
          var indexV = cellValue.indexOf('V')
          if (indexV > -1) {
            var n = Number(cellValue.substr(indexV + 1, 1))
            cellValue = cellValue.replace('V' + n, '变量' + (n + 1))
            return this.formatCode(row, column, cellValue, index)
          }
          return cellValue.replace(/XY/g, '效验')
        } catch (err) {
          return cellValue
        }
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/bus/busbuttons/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'key': this.dataForm.key,
            'type': this.$route.query.type,
            'brandId': this.$route.query.brandid
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
          this.$nextTick(() => {
            this.setSort()
          })
        })
      },
      setSort() {
        const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
        Sortable.create(el, {
          ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
          options: { forceFallback: true },
          onEnd: evt => {
            var newRow = this.dataList[evt.newIndex]
            var oldRow = this.dataList[evt.oldIndex]
            this.$http({
              url: this.$http.adornUrl('/bus/busbuttons/update'),
              method: 'post',
              data: this.$http.adornData({
                'id': newRow.id,
                'sort': oldRow.sort
              })
            })
            this.$http({
              url: this.$http.adornUrl('/bus/busbuttons/update'),
              method: 'post',
              data: this.$http.adornData({
                'id': oldRow.id,
                'sort': newRow.sort
              })
            })
          }
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 复制
      copyHandle(id) {
        this.$confirm('是否确定复制？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/bus/busbuttons/copy'),
            method: 'post',
            data: this.$http.adornData(id, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/bus/busbuttons/delete'),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
